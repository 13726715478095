.ant-tabs-nav {
    margin-bottom: 0 !important;
}
.car-item {
    border: 1px solid rgb(0 0 0 / 10%);
    border-radius: 10px;
    margin-top: 20px;
}
.car-item .car-image {
    position: relative;
}
.car-item .car-image:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 15%);
}
.car-item .car-image img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 250px;
    object-fit: cover;
}
.car-item .car-image .car-badge {
    position: absolute;
    left: 10px;
    background: #1677ff;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 10px;
    border-radius: 10px;
    line-height: 20px;
    text-transform: uppercase;
    top: 10px;
}
.car-item .car-price {
    font-size: 22px;
    font-weight: 500;
}
.car-item .car-content {
    padding: 20px;
}
.car-item .car-content .item-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}
.car-item .car-content .car-list {
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    margin-bottom: 10px;
}
.car-item .car-content .car-list ul.list-inline {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
}
.car-item .car-content .car-list ul.list-inline li {
    font-size: 16px;
    color: #626262;
    margin-right: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.car-item .car-content .car-list ul.list-inline li img {
    height: 18px;
    margin-right: 5px;
}
.car-content .location {
    font-size: 18px;
    color: #626262;
    display: flex;
    align-items: center;
}
.car-content .location img {
    margin-right: 5px;
    height: 18px;
}

/* Arrow Slide */
.safarcar-product .slick-arrow {
    z-index: 1;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .safarcar-product .slick-prev:before, .safarcar-product .slick-next:before {
    content: none;
   }
  .safarcar-product .slick-arrow .anticon {
    background: #1677ff;
    color: #fff;
    width: 40px;
    height: 40px;
    justify-content: center;
    border-radius: 20px;
    transition: all 0.3s ease;
}
.safarcar-product .slick-arrow .anticon:hover {
    background: #1677ffa1;
}
  .safarcar-product  .slick-prev {
    left: -0px;
  }
  
  .safarcar-product .slick-next {
    right: 0px;
  }
  
  .safarcar-product .slick-arrow svg {
    font-size: 20px;
    color: #ffffff;
  }
  
  /* .safarcar-product .slick-slider {
    margin: 0 -15px;
} */
  .safarcar-product .slick-slider .slick-slide {
    margin: 0 15px;
}


/* Tabs */
.ant-tabs-nav-list {
    padding: 10px;
    border: 1px solid #00000014;
    border-radius: 10px;
    margin-bottom: 20px;
}
.ant-tabs .ant-tabs-tab {
    padding: 0;
}
.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    padding: 10px 25px;
    text-align: center;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    background: #1677ff;
    color: #fff;
    border-radius: 7px;
}
.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn a {
    text-decoration: none;
    font-size: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn a {
    background: #1677ff;
    color: #fff;
    border-radius: 7px;
}
.ant-tabs .ant-tabs-tab-btn img {
    width: 50px;
    margin-bottom: 5px;
}
.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn span{
    display: block;
}
.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn a:before {
    content: "";
    height: 1.875rem;
    width: 3.125rem;
    display: flex;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.ant-tabs .ant-tabs-tab:nth-child(1) .ant-tabs-tab-btn a:before {
    background-image: url(../../public/images/bodytype/hatchback.png);
}
.ant-tabs .ant-tabs-tab:nth-child(2) .ant-tabs-tab-btn a:before {
    background-image: url(../../public/images/bodytype/sedan.png);
}
.ant-tabs .ant-tabs-tab:nth-child(3) .ant-tabs-tab-btn a:before {
    background-image: url(../../public/images/bodytype/suv.png);
}
.ant-tabs .ant-tabs-tab:nth-child(4) .ant-tabs-tab-btn a:before {
    background-image: url(../../public/images/bodytype/muv.png);
}
.ant-tabs .ant-tabs-tab:nth-child(5) .ant-tabs-tab-btn a:before {
    background-image: url(../../public/images/bodytype/luxury-sedan.png);
}
.ant-tabs .ant-tabs-tab:nth-child(6) .ant-tabs-tab-btn a:before {
    background-image: url(../../public/images/bodytype/luxury-suv.png);
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn a:before {
    filter: brightness(0) invert(1);
}
.ant-tabs .ant-tabs-nav:before {
    content: none;
}

/* item deatils */
.car-details .ant-card .ant-card-body .wishlist-icon {
    position: absolute;
    right: 10px;
    top: 20px;
}
.car-details .car-name-title {
    padding-right: 40px;
}
.car-details .car-price {
    font-weight: 600;
}
.car-details .sesproduct_information_report_btns._contact {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}
.car-details .sesproduct_information_report_btns._contact > div {
    padding: 0 0.625rem;
    flex-grow: 1;
}
.car-details .sesproduct_information_report_btns._contact button {
    display: block;
    padding: 0.625rem 0.9375rem;
    font-weight: 600;
    border-radius: 0.3125rem;
    margin-right: 0.3125rem;
    min-width: 9.375rem;
    text-align: center;
    text-transform: uppercase;
    height: auto;
    width: 100%;
}

.sesproduct_profile_fields {
    margin-top: 20px;
}
.sesproduct_profile_fields .profile_fields {
    margin-bottom: 20px;
}
.sesproduct_profile_fields .profile_fields .ant-card-head {
    background-color: #f6f6f6;
    border-bottom-width: 1px;
    padding: 0.9375rem;
    font-weight: bold;
}
.sesproduct_profile_fields .profile_fields .ant-card-body {
    padding: 25px 15px;
}
.sesproduct_profile_fields .ant-spin-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px 20px;
}
.sesproduct_profile_fields .ant-spin-container .ant-list-item-meta {
    display: flex;
    width: 31.33%;
}
.sesproduct_profile_fields .ant-spin-container .ant-list-item-meta .ant-list-item-meta-avatar {
    margin-right: 10px;
}
.sesproduct_profile_fields .ant-spin-container .ant-list-item-meta .ant-list-item-meta-avatar svg {
    color: #1677ff;
    font-size: 18px;
    margin-top: 0;
}
.sesproduct_profile_fields .ant-spin-container .ant-list-item-meta .ant-list-item-meta-content h4 {
    font-size: 15px;
    color: #808d97;
    font-weight: normal;
    margin-bottom: 5px;
}
.sesproduct_profile_fields .ant-spin-container .ant-list-item-meta .ant-list-item-meta-content .ant-list-item-meta-description {
    font-weight: 600;
}
.sesproduct_profile_fields .feature-list .ant-list-item-meta {
    width: 48%;
}
.feature-list .ant-list-item-meta-content {
    padding-right: 30px;
    position: relative;
    flex: auto;
}
.feature-list .ant-list-item-meta-content .ant-list-item-meta-description {
    position: absolute;
    right: 0;
    top: 0;
}
.feature-list .ant-list-item-meta-content .ant-list-item-meta-description svg {
    color: #51db1b;
}

.similar-products .ant-card-head {
    background-color: #f6f6f6;
    border-bottom-width: 1px;
    padding: 0.9375rem;
    font-weight: bold;
}
.similar-products .ant-card-body {
    padding: 15px;
}


.sidebar {
    background: #f5f5f5;
    padding: 20px;
}
.sidebar .ant-row {
    display: block;
}
.sidebar .product_sidebar{
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    padding-bottom: 10px;
}
.sidebar .product_sidebar h6 {
    font-size: 16px;
    font-weight: 600;
}
.sidaebar-widget label {
    display: flex;
    margin-bottom: 5px;
}
.sidaebar-widget label.ant-radio-button-wrapper {
    border: none !important;
    background: transparent;
    padding-left: 20px;
    border-radius: inherit;
}
.sidaebar-widget label.ant-radio-button-wrapper .ant-radio-button {
    z-index: 0;
}
.sidaebar-widget label.ant-radio-button-wrapper .ant-radio-button .ant-radio-button-input {
    opacity: 1;
    width: auto;
    height: auto;
}


.safarcar-brows-products .ant-row {
    display: block;
}
.safarcar-brows-products .roduct-filters-badge {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.safarcar-brows-products .roduct-filters-badge .fillter-clear-btn {
    margin-right: 20px;
}
.safarcar-brows-products .roduct-filters-badge > div {
    margin-bottom: 10px;
}
.fillter-product-found {
    margin-bottom: 20px;
    margin-top: 10px;
}


.carsell-content {
    max-width: 800px;
    margin: 0 auto;
}
.carsell-content .carsell-steps {
    background: #f5f5f5;
    margin-top: 20px;
    padding: 20px;
}
.car-submit-from .ant-form-item-label label {
    font-size: 18px;
} 

.carsell-steps .brand-name-list {
    background: #fff;
    text-align: center;
    padding: 15px;
    border-radius: 5px;
    position: relative;
    width: calc(25% - 10px);
    margin-bottom: 10px;
    margin-right: 10px;
}
.carsell-steps .brand-name-list p{
    margin: 0;
}
.carsell-steps .brand-name-list span.ant-radio {
    opacity: 0;
    display: none;
}
.carsell-steps .brand-name-list span {
    width: 100%;
}
.carsell-steps .carsell-brands img {
    max-height: 1.5625rem;
}
.carsell-steps .brand-name-list.ant-radio-wrapper-checked {
    background: #1677ff;
    color: #fff;
}


.rto-location-list {
    display: flex;
    flex-wrap: wrap;
}
.rto-location-list .rto-location-name {
    flex: 0 0 48%;
    text-align: center;
    justify-content: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 0 1%;
    margin-top: 10px;
}
.rto-location-list .rto-location-name span.ant-radio.ant-wave-target {
    display: none;
}
.rto-location-list .rto-location-name.ant-radio-wrapper-checked {
    background: #1677ff;
    color: #fff;
}

.car-manufacturing-year {
    display: flex;
    flex-wrap: wrap;
}
.car-manufacturing-year .car-year {
    flex: 0 0 18%;
    text-align: center;
    justify-content: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 0 1%;
    margin-top: 10px;
}
.car-manufacturing-year .car-year span.ant-radio.ant-wave-target {
    display: none;
}
.car-manufacturing-year .car-year.ant-radio-wrapper-checked {
    background: #1677ff;
    color: #fff;
}

.car-mobel-list {
    display: flex;
    flex-wrap: wrap;
}
.car-mobel-list .car-model-name {
    flex: 0 0 18%;
    text-align: center;
    justify-content: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 0 1%;
    margin-top: 10px;
}
.car-mobel-list .car-model-name span.ant-radio.ant-wave-target {
    display: none;
}
.car-mobel-list .car-model-name.ant-radio-wrapper-checked {
    background: #1677ff;
    color: #fff;
}

.car-fuel {
    display: flex;
    flex-wrap: wrap;
}
.car-fuel .car-fuel-type {
    flex: 0 0 32%;
    text-align: center;
    justify-content: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 0.3125rem;
    margin-top: 10px;
}
.car-fuel .car-fuel-type span.ant-radio.ant-wave-target {
    display: none;
}
.car-fuel .car-fuel-type.ant-radio-wrapper-checked {
    background: #1677ff;
    color: #fff;
}

.car-transmission-list {
    display: flex;
    flex-wrap: wrap;
}
.car-transmission-list .car-transmission {
    flex: 0 0 32%;
    text-align: center;
    justify-content: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin:0.3125rem;
    margin-top: 10px;
}
.car-transmission-list .car-transmission span.ant-radio.ant-wave-target {
    display: none;
}
.car-transmission-list .car-transmission.ant-radio-wrapper-checked {
    background: #1677ff;
    color: #fff;
}

.car-variant-list {
    display: flex;
    flex-wrap: wrap;
}
.car-variant-list .car-variant {
    flex: 0 0 32%;
    text-align: center;
    justify-content: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 0.3125rem;
    margin-top: 10px;
}
.car-variant-list .car-variant span.ant-radio.ant-wave-target {
    display: none;
}
.car-variant-list .car-variant.ant-radio-wrapper-checked {
    background: #1677ff;
    color: #fff;
}

.ownership-category {
    display: flex;
    flex-wrap: wrap;
}
.ownership-category .ownership {
    flex: 0 0 100%;
    text-align: center;
    justify-content: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}
.ownership-category .ownership span.ant-radio.ant-wave-target {
    display: none;
}
.ownership-category .ownership.ant-radio-wrapper-checked {
    background: #1677ff;
    color: #fff;
}

.car-kilometers {
    display: flex;
    flex-wrap: wrap;
}
.car-kilometers .kilometers-number {
    flex: 0 0 100%;
    text-align: center;
    justify-content: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}
.car-kilometers .kilometers-number span.ant-radio.ant-wave-target {
    display: none;
}
.car-kilometers .kilometers-number.ant-radio-wrapper-checked {
    background: #1677ff;
    color: #fff;
}

.car-want-sell-list {
    display: flex;
    flex-wrap: wrap;
}
.car-want-sell-list .car-want-sell {
    flex: 0 0 100%;
    text-align: center;
    justify-content: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}
.car-want-sell-list .car-want-sell span.ant-radio.ant-wave-target {
    display: none;
}
.car-want-sell-list .car-want-sell.ant-radio-wrapper-checked {
    background: #1677ff;
    color: #fff;
}

.car-submit-from button {
    padding: 8px 30px;
    height: auto;
}



.safarcar-brows-products .car-item {
    width: 31.3333%;
}

/* ====== Responsive ====== */

/* 
@media (max-width: 1399px) {

    .safarcar-brows-products .car-item {
        width: 31%;
    }

} */

@media (max-width: 1199px) {


        /* ====== Catr details ====== */

        .sesproduct_profile_fields .ant-spin-container .ant-list-item-meta {
            width: 30.3333%;
        }
        .sesproduct_profile_fields .feature-list .ant-list-item-meta {
            width: 48%;
        }

        /* ====== buy car ====== */
        .safarcar-brows-products .car-item {
            width: 48%;
        }

}


@media (max-width: 991px) {

    .car-details {
        margin-bottom: 30px;
    }
    .car-manufacturing-year .car-year {
        flex: 0 0 31%;
    }
    .car-mobel-list .car-model-name {
        flex: 0 0 31%;
    }
    .car-fuel .car-fuel-type{
        flex: 0 0 31%;
    }
    .car-transmission-list .car-transmission {
        flex: 0 0 31%;
    }
    .car-variant-list .car-variant {
        flex: 0 0 31%;
    }
}


@media (max-width: 767px) {

    .carsell-steps .brand-name-list {
        width: calc(33.3333% - 10px);
    }

    .safarcar-brows-products .car-item {
        width: 100%;
    }
    .car-item .car-content .item-title {
        font-size: 18px;
    }
}


@media (max-width: 479px) {

    .carsell-steps .brand-name-list {
        width: calc(50% - 10px);
    }

    .rto-location-list .rto-location-name {
        flex: 0 0 100%;
    }

    .car-manufacturing-year .car-year {
        flex: 0 0 45%;
    }
    .car-mobel-list .car-model-name {
        flex: 0 0 45%;
    }
    .car-fuel .car-fuel-type{
        flex: 0 0 45%;
    }
    .car-transmission-list .car-transmission {
        flex: 0 0 45%;
    }
    .car-variant-list .car-variant {
        flex: 0 0 45%;
    }



}