.footer-widget {
    margin-bottom: 20px;
}

.footer-widget .sfc-footer-logo {
    margin-bottom: 20px;
}

.footer-widget .sesfooter_about {
    margin-bottom: 20px;
    text-align: justify;
}

.footer-widget ._app_links {
    margin-bottom: 20px;
}

.footer-widget .footer-social-media {
    margin-bottom: 30px;
}

.footer-widget .footer-social-media .social-icons .anticon {
    border: none;
    padding: 0;
    color: #1677FF;
    cursor: pointer;
    font-size: 25px;
    border: 1px solid #1677ff;
    border-radius: 50px;
    padding: 10px;
    margin-right: 20px;
}

.footer-widget .footer-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
}

.footer-widget ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer-widget ul li {
    margin-bottom: 10px;
}

.footer-widget ul.footer-menu {
    display: flex;
    flex-wrap: wrap;
}

.footer-widget ul.footer-menu li {
    flex: 0 0 100%;
}

.footer-widget ul li a {
    text-decoration: none;
    color: #000;
}

.footer-widget ul li a:hover {
    color: #1677ff;
}

.footer_buttons button {
    padding: 10px 25px;
    height: auto;
    margin-right: 20px;
    margin-top: 10px;
}

@media (max-width: 998px) {
    /* .footer-widget{
        margin: 0;
        padding: 0 20px;
    } */
    /* .sfc-footer-logo img {
        margin: auto;
        display: block;
    } */

    /* .sesfooter_about p{
        text-align: center;
    } */

    /* .footer-social-media .social-icons{
        display: flex;
        justify-content: center;
    }
    ._copyright{
        text-align: center;
        margin-bottom: 20px;
    }
    .footer_buttons{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .footer-title{
        margin-top: 20px;
    } */
}

@media (max-width: 575px) {

    .footer_buttons a {
        display: block;
    }

}