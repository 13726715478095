.banner{
    position: relative;

}
.banner:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 20%);
}
.banner-content {
    position: relative;
}

.banner-title{
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 30px;
    font-weight: bold;
}
.banner-description{
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 30px;
}
.banner-content button{
    font-size: 18px;
    font-weight: 600;
    padding: 25px 30px;
    border: none;
}
.btn-primary{
    background-color: #1677ff;
    color: #ffffff;
}

.banner-img{
    background-size: cover;
    background-position: center;
    align-items: center;
    display: flex;
    margin: 0;
    height: 800px;
    color: #fff;
    text-align: left;
}


@media (max-width: 1299px) {

    .banner-img {
        height: 550px !important;
    }

}

@media (max-width: 1024px) {

    .banner-img .banner-content{
        width: 50%;
    }

}


@media (max-width: 991px) {

    .banner-img {
        height: 400px !important;
    }
    .banner-title {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .banner-description {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 20px;
    }

}

@media (max-width: 767px) {

    .banner-img {
        height: 300px !important;
    }
    .banner-title {
        font-size: 30px;
        line-height: 30px;
        width: 50%;
    }
    .banner-description {
        font-size: 20px;
        line-height: 30px;
        display: none;
    }
    .banner-content button{
        padding: 5px 10px !important;
    }

}

@media (max-width: 575Px) {

    .banner-img {
        height: 230px !important;
    }
    .banner-title {
        font-size: 24px;
        line-height: 30px;
    }
    .banner-description {
        font-size: 18px;
        line-height: 26px;
    }
    .banner-img{
        align-items: start;
        padding-top: 10px;
    }
    .banner-img .banner-content{
        width: 100%;
    }

}